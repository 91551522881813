<template>
  <div class="container">
    <NuxtLink
      :to="localePath(url)"
      :class="['image-link', 'link', { 'has-custom-cursor': hasCustomCursor }]"
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave"
    >
      <div class="picture-wrapper">
        <Picture :source-set="sourceSet" />
      </div>
      <CloudinaryImage
        v-if="getFirstIndex(props.item.badge?.cloudinary_asset)?.public_id"
        :public-id="getFirstIndex(props.item.badge?.cloudinary_asset)?.public_id"
        :options="{ width: 230 }"
        :image-classes-override="['badge-image']"
      />
    </NuxtLink>

    <CustomCursor
      v-if="hasCustomCursor && showCustomCursor"
      :outer-cloudinary-asset="item?.custom_cursor?.outer_cloudinary_asset"
      :inner-cloudinary-asset="item?.custom_cursor?.inner_cloudinary_asset"
    />

    <div>
      <div class="title">{{ title }}</div>
      <ol v-if="priceInfo" class="prices">
        <li class="price">
          {{ localeStore.currencySymbol + (priceInfo?.subscriptionPrice || priceInfo?.calculated_price) }}
        </li>
        <li v-if="priceInfo?.price && priceInfo?.price > priceInfo?.calculated_price" class="compare-at-price">
          {{ localeStore.currencySymbol + priceInfo?.price }}
        </li>
      </ol>
    </div>

    <component
      :is="showAtcButton ? 'button' : linkComponent"
      v-if="item.cta?.label"
      v-bind="showAtcButton ? { disabled: isAtcDisabled, onClick: addToCartAction } : { to: localePath(url) }"
      class="cta-button"
    >
      <span class="cta-button-text">
        {{ item.cta?.label }}
      </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="26"
        viewBox="0 0 18 26"
        fill="none"
        class="cta-button-icon"
      >
        <path
          d="M16.8755 23.6995H15.3495C16.5145 22.4634 17.5517 20.6396 17.5497 18.0725C17.5457 13.3342 14.8389 13.1363 15.6946 9.73299C15.7106 9.6724 15.6607 9.58959 15.6009 9.60979C13.65 10.2319 12.9279 12.6758 12.7903 12.6758C13.0257 8.96953 12.4891 4.34432 8.04682 0.026105C7.97899 -0.0385268 7.86928 0.026105 7.89123 0.119013C9.17783 5.5804 7.24892 9.15534 5.96631 9.80772C5.27214 7.21639 3.3572 6.5115 2.79269 6.54382C2.73684 6.54786 2.70094 6.60441 2.71689 6.65894C3.9277 10.9267 0.472821 12.7889 0.47681 17.6241C0.478805 20.4659 1.52604 22.4109 2.69695 23.6975H1.15103C1.04331 23.6975 0.95754 23.7843 0.95754 23.8934V25.8021C0.95754 25.9111 1.04531 25.998 1.15103 25.998H6.35329L6.22563 25.8849C3.5866 23.2491 3.21757 17.7918 7.71769 14.338C7.7895 14.2835 7.88923 14.3441 7.87327 14.4329C7.30079 17.4827 7.80745 19.4459 9.2656 21.1102C10.4265 19.9872 11.392 17.82 11.6234 17.2747C11.6493 17.2121 11.7291 17.1959 11.779 17.2424C14.9227 20.2074 13.2431 24.5478 12.16 25.8869L12.0403 26H16.8735C16.9812 26 17.067 25.9132 17.067 25.8041V23.8954C17.067 23.7864 16.9792 23.6995 16.8735 23.6995"
          fill="#FF6A39"
        />
      </svg>
    </component>
  </div>
</template>

<script setup lang="ts">
import Picture from './Picture.vue';
import { useCloudinary } from '~/composables/cloudinary';
import { useLocaleStore } from '~/store/locale';
import { useProductDataStore } from '~/store/product-data';
import { AddToCartItem } from '~/types/bigcommerce';
import { getFirstIndex } from '~/util/arrays';

function getProductDataFromProps(props: any) {
  const product = props.item.referenceConnection.edges[0].node;
  const productOfferings = product?.product_offerings;
  const productVariations = productOfferings?.product_variations;
  const hasVariants = Array.isArray(productVariations) && productVariations.length > 0;
  const targetVariantSkuOrId = props.item.target_variant;
  const selectedVariant =
    hasVariants &&
    (targetVariantSkuOrId
      ? productVariations.find(({ bc_variant: { sku, id, product_id: productId } }) =>
          [sku, productId, id].includes(targetVariantSkuOrId)
        ) || productVariations[0]
      : productVariations[0]);

  const selectedVariantBcVariant = selectedVariant?.bc_variant;
  const baseProduct = productOfferings?.bc_primary_product?.product;
  const sku = selectedVariantBcVariant?.sku || baseProduct?.sku;
  const variantId = hasVariants ? selectedVariant?.bc_variant?.id : undefined;
  const id = baseProduct?.id;
  const parentSku = hasVariants ? baseProduct?.sku : null;
  const name = selectedVariant ? selectedVariant.display_title : productOfferings?.product_title;
  const brand = productOfferings?.brand?.brand_name;
  const category = (product?.product_taxonomy?.category || []).join(', ');
  const baseImages = productOfferings?.base_image_set?.length ? productOfferings.base_image_set : [];
  const newImages: Array<any> = selectedVariant?.image?.length ? selectedVariant.image : [];
  const images = newImages.concat(baseImages);
  const image = images.length ? images : product?.images;
  let cldImage = '';
  if (image && image?.[0]?.public_id) cldImage = cloudinary.generateImageUrl(image?.[0]?.public_id);
  else if (product?.thumb?.[0]?.file?.url) cldImage = product.thumb[0].file.url;

  return {
    id,
    name,
    variantId,
    hasVariants,
    sku,
    parentSku,
    brand,
    category,
    image: cldImage,
  };
}

async function addToCartAction() {
  const { id, variantId, sku, parentSku, hasVariants, image, name, brand, category } = getProductDataFromProps(props);

  const cartProduct: AddToCartItem[] = [
    {
      quantity: 1,
      product: {
        id,
        variantId,
        sku,
        parentSku,
        image,
        name,
        brand,
        category,
      },
      hasVariants,
    },
  ];

  isAtcDisabled.value = true;
  await useAddToCart({ cartItems: cartProduct });
  isAtcDisabled.value = false;
}

const cloudinary = useCloudinary();
const productDataStore = useProductDataStore();
const localePath = useLocalePath();
const localeStore = useLocaleStore();
const linkComponent = resolveComponent('NuxtLink');

const props = defineProps<{ item: any; index: number }>();

const CARD_VARIANTS = {
  'landscape-lg': {
    transform: {
      width: 676,
      height: 381,
    },
  },
  'portrait-lg': {
    transform: {
      width: 562,
      height: 702,
    },
  },
  'landscape-sm': {
    transform: {
      width: 448,
      height: 253,
    },
  },
  'portrait-sm': {
    transform: {
      width: 448,
      height: 565,
    },
  },
};

const sourceSet = props.item.source_set.map((source: any) => ({
  ...source,
  transform: {
    ...Object.values(CARD_VARIANTS)[props.index].transform,
    ...Object.fromEntries(Object.entries(source.transform).filter(([_, value]) => value !== null)),
  },
}));

const isAtcDisabled = ref(false);
const url = computed(() => props.item.referenceConnection.edges[0].node.url);
const { sku, name } = computed(() => getProductDataFromProps(props))?.value;
const priceInfo = computed(() => (sku ? productDataStore.getPriceBySku(sku) : undefined));
const title = props.item.overrides?.title || name || props.item.referenceConnection.edges[0].node.display_title;
const showAtcButton = computed(() => sku && props.item.cta.action === 'add-to-cart');
const hasCustomCursor = !!getFirstIndex(props.item?.custom_cursor?.outer_cloudinary_asset)?.public_id;
const showCustomCursor = ref(false);

const handleMouseOver = () => {
  showCustomCursor.value = true;
};

const handleMouseLeave = () => {
  showCustomCursor.value = false;
};
</script>

<style lang="scss" scoped>
.picture-wrapper {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;

  :deep(picture, img) {
    width: 100%;
  }
}

.link {
  color: inherit;
}

.title {
  font-family: local-vars.$ff-hlp;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 900;
  line-height: 1;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;

  @include local-mixins.desktop_and_tablet {
    font-size: 1.5rem;
    font-weight: 750;
  }
}

.prices {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: -0.02em;
  display: flex;
  gap: 0.5rem;
}

.compare-at-price {
  opacity: 50%;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background-color: #f9f2e6;
  color: #222222;
  // height: 58.53px;
  padding: 1.1rem 1.5rem;
  gap: 0.5rem;
  border-radius: 8px;
  font-family: local-vars.$ff-hlp;
  font-size: 1.438rem;
  font-weight: 750;
  line-height: 1rem;
  // line-height: 58.53px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;

  &[disabled] {
    opacity: 0.5;
  }
}

.cta-button-text {
  padding: 0.6rem 0 0;
}

.cta-button-icon {
  // margin-top: -2px;
}

.image-link {
  position: relative;
}

.badge-image {
  position: absolute;
  right: 2rem;
  bottom: -1.5rem;
  max-width: 33%;
}

.has-custom-cursor:has(+ .custom-cursor[data-is-loaded='true']) {
  cursor: none;
}
</style>
